define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "a11y": {
      "button": {
        "actions": {
          "copy_string": "Copy {copy_string}",
          "dismiss_dialog": "Dismiss dialog"
        }
      },
      "link": {
        "actions": {
          "show_details": "Click to view details"
        },
        "aria_label": {
          "fallback": "Navigate to {href}"
        }
      }
    },
    "account_statuses": {
      "disabled": "Disabled",
      "enabled": "Enabled"
    },
    "acma": {
      "top_nav": {
        "image_alt": "Australian Communications and Media Authority",
        "image_title": "Australian Communications and Media Authority"
      }
    },
    "actions": {
      "apply": "apply",
      "cancel": "Cancel",
      "click_to_collapse": "Click to collapse",
      "click_to_expand": "Click to expand",
      "confirm": "Confirm",
      "delete": "Delete",
      "edit": "edit",
      "save": "save",
      "update_password": "Update password"
    },
    "auctions": {
      "filters": {
        "dropdown": {
          "all": {
            "admin_user|label": "All Auctions",
            "label": "All Auctions in my Group"
          },
          "live": {
            "admin_user|label": "Live Auctions",
            "label": "Live Auctions in my Group"
          },
          "my": {
            "admin_user|label": "My Auctions",
            "label": "My Auctions"
          }
        }
      },
      "table": {
        "column_groups": {
          "activity": {
            "header": "Activity",
            "style": {
              "header": {
                "border-right": "1px solid #d1d1d1"
              }
            }
          },
          "auctions": {
            "header": "Auctions",
            "style": {
              "header": {
                "border-right": "1px solid #d1d1d1"
              }
            }
          },
          "status": {
            "header": "Status",
            "style": {
              "header": {
                "border-right": "1px solid #d1d1d1"
              }
            }
          },
          "user": {
            "header": "User",
            "style": {
              "header": {
                "border-right": "1px solid #d1d1d1"
              }
            }
          },
          "version": {
            "header": "Version",
            "style": {
              "header": {
                "border-right": "1px solid #d1d1d1"
              }
            }
          }
        },
        "columns": {
          "auction_state": {
            "column_group": "status",
            "header": "Auction State",
            "style": {
              "width": "minmax(160px,1fr)"
            }
          },
          "auction_state_badge": {
            "column_group": "auctions",
            "header": "State",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "badges": {
            "column_group": "status",
            "header": "Badges",
            "style": {
              "alignment": "center",
              "width": "170px"
            }
          },
          "details_link": {
            "column_group": "auctions",
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "group": {
            "column_group": "user",
            "header": "Group",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "last_updated": {
            "column_group": "activity",
            "header": "Last Updated",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "last_used": {
            "column_group": "activity",
            "header": "Last Used",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "name": {
            "column_group": "auctions",
            "header": "Name",
            "style": {
              "width": "minmax(350px,2fr)"
            }
          },
          "owner": {
            "column_group": "user",
            "header": "Owner",
            "style": {
              "width": "minmax(200px,1fr)"
            }
          },
          "project": {
            "column_group": "version",
            "header": "Project",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "release": {
            "column_group": "version",
            "header": "Release",
            "style": {
              "width": "minmax(200px,1fr)"
            }
          },
          "round": {
            "column_group": "status",
            "header": "Round",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "state": {
            "column_group": "status",
            "header": "Status",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "users": {
            "column_group": "status",
            "header": "Users",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "audit_log_entries": {
      "table": {
        "columns": {
          "audit_object_id": {
            "header": "Affected ID",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "category_name": {
            "header": "Category",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "created_at": {
            "header": "Date & Time",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "details": {
            "header": "Details",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "subcategory_name": {
            "header": "Subcategory",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "user_email": {
            "header": "Performer",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "authentication": {
      "login_form": {
        "buttons": {
          "submit": {
            "pending": "Signing in...",
            "text": "Sign In"
          }
        },
        "fields": {
          "password": {
            "label": "Password"
          },
          "two_factor_pin": {
            "hint_text": "If you have not yet set an RSA pin, please leave this field blank.",
            "label": "RSA Pin"
          },
          "two_factor_token": {
            "hint_text": "The token code can be found on your RSA SecurID® device.",
            "label": "RSA Token Code"
          },
          "username": {
            "label": "Username",
            "placeholder": ""
          }
        }
      },
      "third_party_auth": {
        "login_form": {
          "button": {
            "label": "LOGIN.GOV"
          },
          "instructions": "Sign in with",
          "popup_error": "Something went wrong. Please turn off all browser extensions, enable popups, and try again.",
          "redirect_link": {
            "initial_redirect_url": "https://secure.login.gov",
            "label_text": "Sign in with ",
            "logo_alt_text": "Login.gov"
          },
          "sign_up_link": {
            "sign_up_alt_text": "Don’t have a login.gov account?",
            "sign_up_link_text": "Create one here.",
            "sign_up_url": "https://secure.login.gov/sign_up/enter_email"
          }
        },
        "multi_auth_form_divider": {
          "label": "or"
        }
      },
      "two_factor_forms": {
        "authenticate_token": {
          "fields": {
            "user_id": {
              "hint": "",
              "label": "User ID"
            }
          }
        },
        "enter_pin_token": {
          "body": "Please '<span class=\"g-font-bold\">'wait until the RSA token code changes'</span>' on your RSA SecurID® device. Then enter your RSA pin and the new token code.",
          "buttons": {
            "cancel": {
              "text": "Cancel"
            },
            "submit": {
              "text": "Submit pin & token"
            }
          },
          "fields": {
            "two_factor_pin": {
              "label": "RSA Pin"
            },
            "two_factor_token": {
              "hint_text": "The token code can be found on your RSA SecurID® device.",
              "label": "RSA Token Code",
              "validations": {
                "already_used": "The RSA tokencode you entered is identical to your original entry.\nPlease wait until the tokencode changes and then enter the new code.",
                "invalid_length": "RSA token code must be 6 or 8 characters long."
              }
            }
          },
          "headers": {
            "confirm_next_pin_token": "Verify Pin and New Token Code",
            "verify_pin_token": "Re-enter Your Pin and Token Code"
          }
        },
        "enter_token": {
          "body": {
            "confirm_next_token": "Please '<span class=\"g-font-bold\">'wait until the Google Auth token changes'</span>' on your Google Auth application. Then enter the new token.",
            "verify_token": ""
          },
          "buttons": {
            "cancel": {
              "text": "Cancel"
            },
            "submit": {
              "text": "Submit token"
            }
          },
          "fields": {
            "two_factor_token": {
              "hint_text": "The token can be found in your Google Auth application.",
              "label": "Google Auth Token",
              "validations": {
                "already_used": "The Google Auth token you entered is identical to your original entry.\nPlease wait until the token changes and then enter the new token.",
                "invalid_length": "Google Auth token must be 6 characters long."
              }
            }
          },
          "headers": {
            "confirm_next_token": "Verify New Token",
            "verify_token": "Enter Your Token"
          }
        },
        "set_pin": {
          "body": "Please enter a RSA pin within the alloted time to proceed. Your pin must be 4 to 8 digits long and numeric.",
          "buttons": {
            "cancel": {
              "text": "Cancel"
            },
            "submit": {
              "pending": "Submitting pin...",
              "text": "Submit pin"
            }
          },
          "fields": {
            "two_factor_pin": {
              "hint_text": "RSA pin must be 4-8 digits and numeric.",
              "label": "RSA Pin",
              "validations": {
                "already_used": "RSA pin has already been used.",
                "invalid_format": "RSA pin must be 4-8 digits and numeric."
              }
            },
            "two_factor_pin_confirmation": {
              "label": "Confirm RSA Pin",
              "validations": {
                "no_match": "Pin confirmation doesn't match pin."
              }
            }
          },
          "header": "Set Your RSA Pin"
        },
        "timer": {
          "text": "seconds remain to enter your credentials"
        }
      }
    },
    "base_flash_messages": {
      "api_error_fallback": "Your request could not be processed. Please refresh your page and try again."
    },
    "boem": {
      "top_nav": {
        "image_alt": "Bureau of Ocean Energy Management",
        "image_title": "Bureau of Ocean Energy Management"
      }
    },
    "config": {
      "base_flash_messages": {
        "api_error_fallback": "Your request could not be processed. Please refresh your page and try again.",
        "payload_too_large": "E002: Your upload exceeded the maximum file size limit."
      },
      "external_request_max_mb": "20"
    },
    "configs": {
      "table": {
        "columns": {
          "category": {
            "header": "Category",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "description": {
            "header": "Description",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "handle": {
            "header": "Name",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "updated_at": {
            "header": "Last Updated",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "value": {
            "header": "Value",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          }
        }
      }
    },
    "copy_button": {
      "tooltip": "Click to copy"
    },
    "date": {
      "formats": {
        "human": "ddd, MMM D",
        "long": "MM/DD/YYYY",
        "short": "MMM D"
      }
    },
    "datetime": {
      "formats": {
        "human": "ddd, MMM D, h:mm a",
        "long": "MM/DD/YYYY hh:mm:ss a",
        "short": "MMM D, h:mm a"
      }
    },
    "demo": {
      "top_nav": {
        "image_alt": "Power Auctions LLC",
        "image_title": "Power Auctions LLC"
      }
    },
    "disconnected": {
      "flash_messages": {
        "offline": "You temporarily lost your connection. Please check your connection.",
        "online": "You experienced a temporary connection issue. Please refresh your browser."
      }
    },
    "empty_states": {
      "no_logs": "No Logs",
      "no_results": "No matches found."
    },
    "fcc": {
      "top_nav": {
        "image_alt": "Federal Communications Commission",
        "image_title": "Federal Communications Commission"
      }
    },
    "filtering": {
      "apply_filters": "filter",
      "reset_filters": "reset",
      "summary": {
        "long": {
          "all": "Showing {lower_bound} to {upper_bound} of {total} {filter_object_name}",
          "filtered": "Showing {lower_bound} to {upper_bound} of {total} {filter_object_name}",
          "single": "Showing {filter_object_name} {position} of {total}"
        },
        "short": {
          "all": "Showing {count} of {total} {filter_object_name}",
          "filtered": "Showing {count} of {total} {filter_object_name}"
        },
        "simple": {
          "all": "Showing {count} {filter_object_name}",
          "filtered": "Showing {count} {filter_object_name}"
        }
      }
    },
    "form_validations": {
      "alphanumeric_and_dashes": "Can only contain alphanumeric values and dashes.",
      "field_required": {
        "custom_description": "{description} can't be blank.",
        "default": "'{description}' can't be blank."
      }
    },
    "loading_substate": {
      "basic": "Loading…"
    },
    "messages": {
      "discard_changes_dialog": {
        "body": "Do you want to discard your changes?",
        "header": "Discard changes"
      }
    },
    "multi_user_select_form": {
      "card": {
        "avatar": "{avatar_string}",
        "description_company_user": "({user_id}, {company_name})",
        "description_privileged_user": "(User ID {user_id})",
        "label": "{user_role}"
      },
      "legend": "There are multiple accounts associated with your email: {email_address}. Please choose an account to sign in."
    },
    "override_configurations": {
      "table": {
        "columns": {
          "badges": {
            "header": "Badges",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "description": {
            "header": "Description",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "editable_row": {
            "allow_empty_header": "true",
            "header": "",
            "style": {
              "width": "70px"
            }
          },
          "name": {
            "header": "Name",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "release_created_at": {
            "header": "Latest Release Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "release_name": {
            "header": "Latest Release",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "page_title_em_dash": "-",
    "profiles": {
      "table": {
        "columns": {
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "name": {
            "header": "Name",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "updated_at": {
            "header": "Updated",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "projects": {
      "table": {
        "columns": {
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "name": {
            "header": "Name",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "style": {
            "header": "Auction Style",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "theme": {
            "header": "Theme",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "translation_name": {
            "header": "Translation Name",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "updated_at": {
            "header": "Updated",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "releases": {
      "table": {
        "columns": {
          "back_end": {
            "header": "Back End",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "badges": {
            "header": "Badges",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "description": {
            "header": "Description",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "front_end": {
            "header": "Front End",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "name": {
            "header": "Name",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "override_configuration": {
            "header": "Override <br>Configuration",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "project": {
            "header": "Project",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "publisher": {
            "header": "Publisher",
            "replace_empty_value_with": "None",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "realtime": {
            "header": "Realtime",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "translation": {
            "header": "Translation",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "services": {
      "table": {
        "columns": {
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "state": {
            "header": "State",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "status_checked_at": {
            "header": "Last Status Check",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "type": {
            "header": "Type",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          }
        }
      }
    },
    "singular_subjects": {
      "role": "role",
      "user": "user"
    },
    "subjects": {
      "auction": "{count, plural, one {auction} other {auctions}}",
      "audit_log_entry": "{count, plural, one {audit log entry} other {audit log entries}}",
      "config": "{count, plural, one {config} other {configs}}",
      "override_configuration": "{count, plural, one {override configuration} other {override configurations}}",
      "profile": "{count, plural, one {profile} other {profiles}}",
      "project": "{count, plural, one {project} other {projects}}",
      "release": "{count, plural, one {release} other {releases}}",
      "service": "{count, plural, one {service} other {services}}",
      "two_factor_token": "{count, plural, one {two factor token} other {two factor tokens}}",
      "user": "{count, plural, one {user} other {users}}"
    },
    "symbols": {
      "dot": "•",
      "separated_by_colon": "{title}: {string}",
      "surround_with_parens": "({string})"
    },
    "time": {
      "all_timezone_abbr": "ET",
      "am": "am",
      "formats": {
        "human": "h:mm a",
        "long": "hh:mm:ss a",
        "military": "H:mm"
      },
      "pm": "pm",
      "timezone": "America/New_York",
      "timezone_abbr": "ET"
    },
    "two_factor_tokens": {
      "table": {
        "columns": {
          "auth_count": {
            "header": "Authentication Count",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "id": {
            "header": "Token Serial",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "last_auth_instance": {
            "header": "Last Authentication Instance",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "last_auth_time": {
            "header": "Last Authentication Time",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "last_auth_user_id": {
            "header": "Last Authentication User",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "next_token_mode": {
            "header": "Next Token Mode",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "note": {
            "header": "Usage Note",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "state": {
            "header": "Enabled",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    },
    "users": {
      "filters": {
        "dropdown": {
          "all": {
            "label": "Show All Groups"
          },
          "disabled": {
            "label": "Show Disabled Users"
          }
        }
      },
      "table": {
        "columns": {
          "created_at": {
            "header": "Created",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "details_link": {
            "header": "Details",
            "style": {
              "alignment": "center",
              "width": "50px"
            }
          },
          "email": {
            "header": "Email",
            "style": {
              "width": "minmax(200px,2fr)"
            }
          },
          "group": {
            "header": "Group",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "last_activity_time": {
            "header": "Last Activity Time",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "must_change_password": {
            "header": "Must Change Password",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "state_badge": {
            "header": "State",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "token_serial": {
            "header": "Token Serial",
            "show_in_modes": "two_factor",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "two_factor_mode": {
            "header": "Two Factor Mode",
            "show_in_modes": "two_factor",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          },
          "user_roles": {
            "header": "User Roles",
            "style": {
              "width": "minmax(100px,1fr)"
            }
          }
        }
      }
    }
  }]];
  _exports.default = _default;
});